@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

$primary: #062330;
$info: #46A8F9;
$secondary: #F1F2F3;
$danger: #E54D3A;
$light: #FFFFFF;

$text-muted: #999999;
$body-color: #535763;
$body-bg: #F7F9FB;

$border-color: #DDDDDD;

$table-hover-bg: #f8f9fa;

$font-family-base: 'Poppins', sans-serif;

$enable-validation-icons: false;
$enable-negative-margins: true;

@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$spacers: map-merge(
                $spacers,
                (
                        _75x: $spacer * .75,
                        5x: $spacer * 5,
                )
);

$font-sizes: map-merge(
                $font-sizes,
                (
                        _75x: $font-size-base * .75,
                        1_125x: $font-size-base * 1.125,
                )
);

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack


.btn-info {
  color: #FFFFFF !important;
}

.smaller {
  font-size: .75rem;
}

.fw-500 {
  font-weight: 500;
}
