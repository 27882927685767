@import "styles/custom_bootstrap";
@import "~react-notifications-component/dist/scss/notification";
@import "~@sendbird/uikit-react/dist/index.css";
@import "~react-datepicker/dist/react-datepicker.css";

$calendar-border: transparent;
//$cell-border: transparent;
$event-bg: transparent;
$event-border: none;
$event-padding: 0;
$event-border-radius: 0;
@import '~react-big-calendar/lib/sass/styles';

body {
  min-width: 1200px;
}

.mwc {
  max-width: 1440px;
  @extend .mx-auto;
  @extend .px-3;
}

.cursor-pointer {
  cursor: pointer;
}

a,
a:visited,
a:active,
a:hover,
a:link {
  text-decoration: none;
}

.img-as-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.img-small {
  max-width: 200px;
  max-height: 200px;
  height: auto;
  width: auto;
}

.sendbird-message-input .sendbird-message-input--textarea {
  overflow-y: hidden;
}
